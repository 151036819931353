.main-div {
  /* background: rgb(0, 0, 0); */
  background-image: url('https://portfolio-ks.s3.ap-south-1.amazonaws.com/background-image-galaxy.gif');
  /* background-image: url('https://i.pinimg.com/originals/f6/32/b0/f632b07f8ab9b2a0ccf77998c766064b.gif'); */
  min-height: 100vh;
  width: 100%;
  display: flex;
  /* flex-direction: column; */
  /* padding-top: 15px; */
  justify-content: space-between;
  box-sizing: border-box;
  overflow-y: scroll;
  /* background-repeat: no-repeat; */
  /* background-position: center; */
  /* background-size: cover; */

}

.profile-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* align-items: center; */

  /* justify-content: end; */
  /* padding: 10px ;
    padding-right: 50px; */
  font-size: Clamp(20px, 2.7vw, 40px);
  font-weight: 600;
  width: 50%;
  /* border: 2px solid rgb(0, 0, 0); */

  /* height: auto; */

}

.profile-details1 {
  /* margin-left: 25px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
  justify-content: center;
  /* border: 2px solid rgb(0, 255, 251); */
  border-radius: 50px 5px;
  box-sizing: border-box;
  padding: 15px;
  color: white;
  width: 100%;
  height: 75vh;
}

.photo-details {
  display: flex;
  /* flex-direction: column; */
  color: white;
  align-items: center;
  gap: 15px;
  /* justify-content: space-between; */
  width: 100%;
}

.firstname {
  font-weight: 800;

  /* font-size: 160px; */
  -webkit-text-stroke: 0.01px rgb(255, 255, 255);
  /* -webkit-font-smoothing: antialiased */
}

.typing-home {
  font-size: Clamp(15px, 1.7vw, 25px);
}
.stars{
  /* margin-right: 250px; */
  /* padding-bottom: 250px; */
  display: flex;
  /* gap: 10px; */
  flex-direction: column;
  /* align-items: center;
  justify-content: flex-start; */
  /* border: 1px solid red; */
  width: 100%;
  padding-left: 20px;
  /* margin-bottom: 150px; */
  box-sizing: border-box;
  
}

.star-1,.star-3{
  width: 20%;
 display: flex;
 justify-content: center;
 
}

.star-2{
  display: flex;
  justify-content: center;
  justify-content: space-between;
  width: 20%;
}
.stars img {
  width: 25px; /* Set the initial width of the star images */
  height: 25px; /* Set the initial height of the star images */
  transition: all 0.3s ease; /* Add a smooth transition effect */
}
.hi-emoji{
  width: 150px;
  height: 50px;
}
.star-1:hover img,.star-3:hover img,.star-2-img1:hover,.star-2-img2:hover {
  cursor: pointer;
  transform: scale(1.5); /* Increase the size on hover */
}
.only-details {
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 20px;
  width: 50%;
}

.photo-details img {
  border-radius: 50%;
  border: 1px solid black;
  width: 250px;
  height: 250px;
  /* filter: invert(1); */
}

.firstname {
  color: #0011ff;
  text-shadow: 3px 3px;
}

.lastname {
  color: #722f39;
}

.flipping-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
  width: 75%;
  height: 92vh;

}

.flip-card {
  width: 22vw;
  height: 150px;
  perspective: 1000px;
  cursor: pointer;
  border-radius: 10px;
}

.flip-card-inner {
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.5s;
  transform: rotateY(0deg);
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  backface-visibility: hidden;
  border-radius: 40px;
  font-size: 20px;
  box-sizing: border-box;
  /* border: 3px solid transparent; */
  border-radius: 50px 5px;
  /* border-image: linear-gradient(45deg, #ffffff, #ff00c8) 1;
    animation: border 2s linear infinite; */
  padding: 5px;
  /* transition: border-color 1s linear;
    box-shadow: rgba(125, 125, 182, 0.25) 0px 13px 27px -5px, rgba(255, 255, 255, 0.3) 0px 8px 16px -8px; */
}

.flip-card-back {
  font-size: 15px;
}

.flip-card-front {
  background-color: #3498db;
}

.flip-card-back {
  background-color: #e74c3c;
  transform: rotateY(180deg);
}

/* @keyframes border {
    0% {
      border-image: linear-gradient(45deg, #ffffff, #ff00a6) 1;
    }
    25% {
      border-image: linear-gradient(45deg, #ff0040, #ffffff) 1;
    }
    50% {
      border-image: linear-gradient(45deg, #ff00a6, #ff0040) 1;
    }
    75% {
      border-image: linear-gradient(45deg, #ffffff, #ff00a6) 1;
    }
    100% {
      border-image: linear-gradient(45deg, #ff0040, #ffffff) 1;
    }
  } */
@media screen and (max-width: 1020px) {
  .main-div {
    padding-top: 150px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    align-items: center;
    justify-content: space-around;

  }

  .profile-details {
    width: 99%;

  }

  .profile-details1 {
    width: 80%;
    display: flex;
    align-items: center;
    /* justify-content: space-between; */

  }

  .flipping-cards {
    width: 100%;
  }
}

@media screen and (max-width: 550px) {
  .photo-details {
    display: flex;
    flex-direction: column;

  }
  .firstname{
    -webkit-text-stroke: 0px rgb(255, 255, 255);
    text-shadow: 0px 0px;
  }
}

@media screen and (max-width: 1000px) {
  .flipping-cards {

    display: flex;
    flex-wrap: wrap;
    /* flex-direction: column; */
    gap: 10px;
    align-items: center;
    justify-content: center;

  }

  .flip-card-front,
  .flip-card-back {
    width: 50vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 10px;
  }

  .flip-card {
    width: 50vw;
    height: 150px;
    perspective: 1000px;
    cursor: pointer;
    border-radius: 10px;
  }

  .flip-card-inner {
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: transform 0.5s;
    transform: rotateY(0deg);
  }

  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }
}

@media screen and (max-width: 1020px) {
  .only-details {
    width: 100%;
  }
}
.main-div::-webkit-scrollbar {
  width: 0;  /* Set the width to 0 to hide the scrollbar */
}

/* Optional: You can style the track and handle if needed */
.main-div::-webkit-scrollbar-track {
  background-color: transparent;  /* Track color (optional) */
}

.main-div::-webkit-scrollbar-thumb {
  background-color: transparent;  /* Scrollbar handle color (optional) */
}

@media screen and (max-width: 300px){
  .photo-details img{
    width: 200px;
    height: 200px;
  }
}