.skills-items{
    width: 100%;
    display: flex;
   
    justify-content: space-between;
    box-sizing: border-box;
    word-break: break-all;
    height: 70vh;
    /* border: 1px solid black; */
}

.vertical-align {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 55%;
    height: 70vh;
    /* border: 1px solid red; */
    box-sizing: border-box;
    padding: 10px;
    overflow-y: auto;
    /* border-bottom: 1px solid black;
    border-right: 1px solid black; Use 'auto' to show the scrollbar only when needed */
  }
  .vertical-align::-webkit-scrollbar {
    width: 5px;
    cursor: pointer;
  
     /* Set the width of the scrollbar */
  }
  .vertical-align::-webkit-scrollbar:hover{
    cursor: pointer;
  }
  .vertical-align::-webkit-scrollbar-thumb {
    background-color: #9f9e9e; /* Set the color of the thumb */
    border-radius: 15px; /* Set the border radius of the thumb */
    overflow-y: hidden;
  }
  
  .vertical-align::-webkit-scrollbar-track {
    background-color: rgb(195, 195, 195); /* Set the color of the track */
  }
  .vertical-align::-webkit-scrollbar-thumb:hover{
    background-color: rgb(121, 121, 121);
  }
  /* Style the scrollbar for Firefox */
  .vertical-align {
    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1; /* thumb and track colors */
  }
.dragging-items{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  padding-left: 20px;
   
    align-items: flex-start;
    justify-content:flex-start ;
    /* box-sizing: border-box; */
    
   
}
.dropping-items{
  display: flex;
  flex-direction: column;
  gap: 10px;
    width: 35%;
    padding: 30px;
    /* border: 1px solid black; */
   margin-right: 10px;
   margin-top: 10px;
}
.dropped-desc{
  padding: 20px;
 
}
.drop-content{
  padding: 10px;
  text-align: center;
}
.items-dragging{
    border: 1px solid grey;
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    justify-content: center;
    gap: 10px;
    text-align: left;
    width: 175px;
    height: 60px;
    cursor: move;
    border-radius: 8px;
border: 1px solid black;
    
}
.dropping-div{
  border: 1px solid black;
  width: 100%;
  height: 80%;
  border-radius: 15px;
  overflow-y: scroll;
 
 
}
.dropping-div::-webkit-scrollbar {
  display: none;
}
.dropped-head{
  border: 1px solid black;
  width: 100%;
  height: 70px;
  font-size:  Clamp(5px, 1vw, 15px);
  text-align: center;
  /* margin: 10px; */
  box-sizing: border-box;
  border-radius: 15px;
  /* margin-right: 10px; */
}
.items-dragging:hover{
    border: 1px solid blue;
    /* box-shadow: blue 0px 5px 15px; */
    box-shadow: rgb(70, 70, 139) 0px 7px 29px 0px;
}
.items-dragging span{
    font-weight: 600;
}
.items-dragging img{
    width: 40px;
    height: 40px;
}
.skill-heading{
    padding-top: 10px;
    padding-left: 20px;
    font-weight: 600;
    margin: 5px;
    color: blue;
    font-size: 20px;
    text-decoration: underline;
}
.dropped-img-span{
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 600;
    box-sizing: border-box;
    
}

.drag-img{
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 100px;
    padding-right: 50px;
    box-sizing: border-box;
}
.horizonLine{
    border: 1px solid blue;
    width: 99.9%;
    
}
@media screen and (max-width: 500px){
.dropping-items,.drag-img{
  display: none;
}

.skills-items{
  width: 100%;
  
}
.vertical-align{
  width: 100%;
  /* display: flex;
    flex-direction: column;
    justify-content: center; */
}
}