.project-page {
    width: 100%;
    height: 70vh;
    border-bottom: 1px solid black;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    overflow-y: scroll;
    background-image: url('https://img.freepik.com/free-photo/blue-surface-with-study-tools_23-2147864592.jpg?size=626&ext=jpg&uid=R116152838&ga=GA1.1.1462843302.1696500966&semt=ais');
    background-repeat: no-repeat;
    background-size: cover;
}

.project-div {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
    align-items: center;
    justify-content: center;
    padding: 40px;
    box-sizing: border-box;

}

.project-card {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    border: 1px solid black;
    padding: 20px;
    word-wrap: break-word;
    width: 20vw;
    height: 56.5vh;
    background: white;
    border-radius: 20px;
}

.project-card img {
    width: 100%;
    height: 125px;
    border-radius: 10px;
}

.project-heading {
    font-size: Clamp(15px, 1.4vw, 25px);
    font-weight: 700;
}

.see-more {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
}




/* from here popup */
.proj-pop-up {
    width: 100%;
    /* height: 100vh; */
    box-sizing: border-box;
    background-color: rgba(0, 19, 37, 0.5);
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    padding-top: 5vh;
    padding-bottom: 10vh;
}

.proj-pop-div {
    width: 60%;
    word-wrap: break-word;
    /* height: 150px; */
    background-color: aliceblue;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 20px;
    gap: 25px;
    border-radius: 25px;
}

.project-close-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-size: Clamp(10px, 1.4vw, 25px);
    font-weight: 600;
}

.project-close-div img {
    width: 30px;
    height: 30px;
    filter: grayscale(10%);
    cursor: pointer;
}

.project-vide {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    width: 100%;

    height: 200px;
    gap: 20px;
}

/* .project-vide img{
    width: 50%;
    height: 200px;
} */
.video-div,
.project-vide img {
    border-radius: 10px;
    overflow: hidden;
    width: 50%;
    height: 100%;
}

.horizontallone {
    height: 80%;
    border: 1px solid grey;
}

.heading-styles {
    font-size: 20px;
    font-weight: 600;
    color: black
        /* here color has to change */
}

.project-links {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 25px;
    width: 100%;
}

.project-links img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    border: 1px solid black;
    cursor: pointer;
}

.anchor {
    display: flex;
    align-items: center;
    gap: 5px;
}

.anchor:hover {
    color: blue;
    text-decoration: underline;
}
.proj-dates{
    display: flex;
    align-items: center;
   
}
.proj-start-text{
    font-weight: 600;
}

@media screen and (max-width: 600px) {
    .proj-pop-up{
        /* padding-top: 500px; */
        /* height: 220vh; */
    }
    /* .proj-pop-div{
       margin-top: 200px;
    } */
    .project-vide {
        display: flex;
        flex-direction: column;
        height: 300px;
    }
    .video-div,.project-vide img{
        width: 100%;
        /* height: 25vh; */
    }
    .horizontallone{
        border: 1px solid black;
        width: 80%;
        height: 1px;
    }
    .project-desc{
        display: none;
    }
    .project-card{
        width: 50vw;
        height: 40vh;
    }
    .proj-dates{
        display: flex;
        flex-direction: column;
    }
}
