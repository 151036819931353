.image-container{
    width: 100%;
    height: 100%;
    border-radius: 50%;
    overflow: hidden;
  }
  
  .rounded-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .education-hierarchy {
    position: relative;
    border-bottom: 1.5px solid #b557a8;
    background-image: url('https://marathon-web-assets.s3.ap-south-1.amazonaws.com/org-background.svg');
    background-repeat: repeat;
    background-size: auto;
  }
  .text-container-info {
    border: 1.5px solid #b557a8;
    position: absolute;
    background-color:#fef8fd;
    right: 10px;
    top: 20px;
    border-radius: 15px;
    box-sizing: border-box;
    padding: 5px;
  }