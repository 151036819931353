@import url('https://fonts.googleapis.com/css2?family=Playpen+Sans:wght@300&display=swap');
body {
  margin: 0;
  font-family: 'Playpen Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


a{
  text-decoration: none;
  color: inherit;
}
/* img {
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
} */