.contact-page{
  /* background-color: rgb(64, 60, 60);
  
  color: white; */
    padding: 20px;
    box-sizing: border-box;
    background-image: url('https://portfolio-ks.s3.ap-south-1.amazonaws.com/contact-bg.png');
    background-repeat: no-repeat;
   background-size: cover;
   background-position: center;
    /* height: 100vh; */
}
.flex-links{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 50px;
    margin-top: 170px;
}
.nav-to-home{
    display: flex;
    align-items: center;
    justify-content: left;
    /* padding-left: 50px; */
    /* cursor: pointer; */
    width: 100%;
   
}
.nav-to-home img{
    width: 35px;
    height: 35px;
}
.nav-home-div{
    display: flex;
    cursor: pointer;
    align-items: center;
    gap: 10px;
    /* filter: invert(1); */
    padding: 10px;
    font-weight: 600;
    background: white;
    border-radius: 15px;
}
.contact-me{
    font-size: Clamp(35px, 4.3vw, 70px);
    /* font-size: 70px; */
    font-weight: 800;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: orange;

}
.contact-desc{
    font-size: 25px;
    font-weight: 500;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 20px;
}
.logo{
    width: 40px;
    height: 40px;
}
.logo-circle{
    /* border: 1px solid black; */
    padding: 50px;
    border-radius: 50%;
    height: 80px;
    width: 80px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    box-shadow: rgba(39, 39, 40, 0.2) 0px 7px 29px 0px;
    color: white;

}
/* .logo-circle img{
    filter: invert(1);
} */
.link-logo{
    width: 25px;
    height: 25px;
}
.link-style{
    display: flex;
    align-items: center;
    gap: 5px;
}
.get-touch{
    display: flex;
align-items: center;
gap: 10px;
text-decoration: underline;
color: blue;
justify-content: right;
margin-top: 30px;
padding-right: 70px;
font-size: 25px;
}
.get-touch img{
    width: 35px;
    height: 35px;
    
}


/* get in touch */
.get-in-touch{
    display: flex;
    /* align-items: center; */
    width: 100%;
    box-sizing: border-box;
    /* gap: 50px; */
}
.get-touch-link{
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
}
.back-icon-btn{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    padding-left: 65px;
    gap: 5px;
    margin-top: 25px;
}
.userName{
    font-size: 30px;
    font-weight: 800;
}
.profile-logo-contact{
    width: 300px;
    height: 300px;
   margin-top: 45px;
   border-radius: 50%;
}
.contact-profile{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 35%;
    background: rgb(121, 225, 253);
    height: 100vh;
    padding-top: 10px;
    box-sizing: border-box;
}
.heading-get-touch{
    font-size: 50px;
    text-align: center;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 15px;
}
.form-page{
    width: 64%;
    display: flex;
    flex-direction: column;
    background: white;
}
.form{
    /* border: 1px solid black; */
    
    display: flex;
    align-items: center;
    justify-content: center;
}
.form-box{
    margin-top: 100px;
    width: 75%;
    border: 1px solid black;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: left;
    border-radius: 50px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    background: #fffaf0;
}

.individual-input,.individual-btn{
  
    /* width: 60px; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 95%;
    padding: 5px;
   
}
.individual-input label{
    font-size: 20px;
}
.individual-input input{
    width: 70%;
    height: 50px;
    font-size: 20px;
    border-radius: 25px;
    padding-left: 10px;
    background: #fffaf0;
    outline: none;
}
.individual-input textarea{
    width: 70%;
    height: 150px;
    font-size: 20px;
    border-radius: 25px;
    padding-left: 10px;
    background: #fffaf0;
    outline: none;
    border: 1.5px solid black;
}
.individual-btn{
    justify-content: center;
}
.individual-btn button{
    padding: 10px;
    width: 100px;
    cursor: pointer;
    border: none;
    background: orange;
    color: white;
    border-radius: 25px;
    font-size: 15px;
}
.input-typing{
    border: none;
    /* outline: none; */
    background: inherit;
    color: rgb(0, 0, 0);
    font-size: 20px;
    text-align: center;
    font-weight: 800;
}

.individual-input-1{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    color: #25D366;
    font-weight: 600;
}
@media screen and (max-width: 800px){
    .get-in-touch{
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .contact-profile{
        width: 100%;
    }
    .form-page{
        width: 100%;
    }
}
@media screen and (max-width: 500px){
    .individual-input{
        display: flex;
        flex-direction: column;
    }
}
@media screen and (max-width: 350px){
.profile-logo-contact{
    width: 250px;
    height: 250px;
}
}